import React from 'react'
import styled from 'styled-components'

import ContentDefault from '../../../components/container'
import Layout from '../../../components/layout'
import banner from '../../../images/banner.png'

const Banner = styled.div`
  width: 100%;
  height: 160px;
  background-image: url(${banner});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
`

const Content = styled(ContentDefault)`
  padding: 50px 0;
  min-height: 750px;
`

const Title = styled.h1`
  color: #102549;
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 32px;
`

const Text = styled.p``

const CursoPilotoPrivado = () => (
  <Layout title='Curso de Piloto Privado' page='educacao/cursos/piloto-privado'>
    <Banner />
    <Content>
      <Title>Curso de Piloto Privado</Title>

      <Text>
        O curso de piloto privado, tanto de avião como de helicóptero, é o primeiro passo
        a ser galgado pelo candidato a piloto, habilitando o profissional a voar em
        aeronaves monomotoras em condições visuais satisfatórias.
      </Text>

      <Text>
        Em atendimento ao Regulamento Brasileiro de Aviação Civil, o curso de piloto
        privado tem por objetivo fornecer os subsídios teóricos e práticos de que
        necessita o piloto privado de avião ou de helicóptero, para desempenhar com
        eficiência e segurança a pilotagem aérea.
      </Text>
      <Text>
        A duração do curso de piloto privado é de aproximadamente 15 semanas, com carga de
        272 horas, sendo ministradas 4 horas/dia.
      </Text>
      <Text>Estão previstos os seguintes cursos para o exercício de 2015:</Text>
      <Text>
        a) Curso Teórico de Piloto Privado de Avião; – em andamento (1ª turma); SEGUNDA
        TURMA, INSCRIÇÕES ABERTAS.
      </Text>
      <Text>
        b) Curso Teórico de Piloto Privado de Helicóptero:- A definir; (O Aeroclube pode
        adiar o início dos cursos caso não atinja o número mínimo de alunos)
      </Text>
      <Text>
        – Curso em andamento A prática de voo acontece durante todos os dias do ano
        mediante agendamento com a Diretoria de instrução do Aeroclube.
      </Text>
      <Text>
        ***O Aeroclube pode adiar o início dos cursos caso não atinja o número mínimo de
        alunos.
      </Text>
      <Text>Maiores informações na Secretaria do Aeroclube. Telefones 3211-1480.</Text>
    </Content>
  </Layout>
)

export default CursoPilotoPrivado
